import React from "react";

import { _RichNavigation__OfferLink as Value } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";

import Flair from "../../../common/Flair";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const RichNavigationOfferLink = (props: Props) => {
    if (!props.value.offer) {
        return null;
    }
    return (
        <a href={props.value.offer.url}>
            {props.value.flair && <Flair flair={props.value.flair} />}
            <RichTextBlock value={props.value.text ?? ""} />
            {props.value.image && (
                <ImageChooserBlock
                    attrs={{ className: styles.img }}
                    value={props.value.image}
                />
            )}
        </a>
    );
};
